<template>
  <div class="box p-5 rounded-md mt-5">
    <div class="flex items-center border-b border-slate-200/60 pb-5 mb-5">
      <div class="font-medium text-base truncate">
        <app-i18n :code="`order.view.${type}Details`"></app-i18n>
      </div>
    </div>
    <div class="flex flex-col items-center gap-3">
      <p class="font-medium text-base text-center">
        {{ i18n('order.view.noCaptain') }}
      </p>
      <p class="font-medium text-base">
        {{ i18n('order.view.assignCaptian') }}
      </p>
      <div class="grid grid-cols-12 w-full items-center">
        <div class="col-span-12 lg:mt-0 mt-2">
          <v-select
            :options="filteredCaptains || []"
            v-model="captain"
            class="select-center"
            style="width: 100%"
            title="Select item"
            label="fullName"
            :loading="captainLoading"
          >
            <template #option="{ avatar, fullName, phoneNumber, email }">
              <div class="flex items-center gap-4">
                <div class="w-9 h-9 image-fit zoom-in">
                  <Tippy
                    tag="img"
                    alt="Midone - HTML Admin Template"
                    class="rounded-lg border-white shadow-md"
                    :src="avatar ? avatar : '/images/default-avatar.svg'"
                    :content="i18n('users.table.profilePicture')"
                  />
                </div>
                <div>
                  <span class="font-medium whitespace-nowrap">{{
                    fullName
                  }}</span>
                  <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
                    {{ email || phoneNumber }}
                  </div>
                </div>
              </div>
            </template>
            <template #no-options>
              <div>
                {{ isRTL ? 'لا يوجد كابتن متاح' : 'No captains available' }}
              </div>
            </template>
            <template #spinner="{ loading }">
              <div v-if="loading" class="vs__spinner"></div> </template
          ></v-select>
          <!-- <TomSelect
            v-model="captainId"
            :load="loadCaptains"
            selectedOption=""
            :shouldLoad="shouldLoad"
            :placeholder="i18n('order.view.searchForCaptain')"
            class="w-full"
          >
            <option disabled>
              {{ isRTL ? 'من فضلك اختر كابتن' : 'Please choose captain' }}
            </option>
            <option
              v-for="(captain, index) in filteredCaptains"
              :key="index"
              :value="captain.id"
            >
              {{ captain.fullName || captain.email }}
            </option>
          </TomSelect> -->
        </div>
      </div>
      <div class="flex justify-center items-center">
        <AppButton
          type="button"
          class="btn bg-theme-36 text-white cursor-pointer"
          :class="!saveLoading ? 'w-24' : ''"
          :disabled="saveLoading || uploadLoading"
          :loading="saveLoading"
          @click="doSubmit"
        >
          <strong>{{ i18n('order.view.assign') }}</strong>
          <template v-slot:loading>
            <app-i18n code="common.loading"></app-i18n>
            <LoadingIcon
              icon="three-dots"
              color="#FFFFFF"
              style="margin: 0 4px"
            />
          </template>
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'
import Message from '@/shared/message/toastify'

export default {
  name: 'app-order-assign-to-captian',
  props: {
    type: {
      type: String
    },
    orderId: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      // captains: 'user/list/rows',
      // captainLoading: 'user/list/loading',
      captains: 'order/form/captains',
      captainLoading: 'order/form/listLoading',
      saveLoading: 'order/form/saveLoading'
    }),
    filteredCaptains() {
      return this.captains.filter(captain => !captain.deliveringOrder)
    },
    userId() {
      return this.user.id
    }
  },
  data() {
    return {
      captain: null,
      errorMessage: null
    }
  },
  async mounted() {
    await this.doFetch({ orderId: this.orderId })
  },
  methods: {
    ...mapActions({
      // doFetch: 'user/list/doFetch',
      doFetch: 'order/form/doListCaptainsInArea',
      doAssignCaptain: 'order/form/doAssignCaptain'
    }),
    isFormValid() {
      if (!this.captain) {
        this.errorMessage = this.isRTL
          ? 'من فضلك اختر كابتن'
          : 'Please choose captain'
        return false
      }
      return true
    },
    async doSubmit() {
      // if (!this.captianId) this.captainId = this.filteredCaptains[0].id
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      await this.doAssignCaptain({
        orderId: this.orderId,
        captainId: this.captain.id
      })
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    }
  }
}
</script>
