<template>
  <div v-if="isShow">
    <div class="box mt-5 grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <div class="intro-y col-span-12 2xl:col-span-6">
          <!-- sm:py-5 -->
          <div
            class="flex items-center justify-end gap-3 px-5 py-3 border-b border-gray-200 dark:border-dark-5"
          >
            <a
              v-if="
                record.status === 'inProgress' || record.status === 'delivering'
              "
              href="javascript:;"
              data-toggle="modal"
              data-target="#cancel-order-modal"
              :style="loading ? 'pointer-events: none' : ''"
              class="btn text-white bg-theme-24 rounded-md font-bold transition duration-300 ease-in-out flex items-center gap-2"
            >
              <XCircleIcon class="w-4 h-4 font-bold" />
              {{ i18n('common.cancel') }}
            </a>
            <a
              v-if="record.status === 'inProgress' || record.status === 'delivering'"
              href="javascript:;"
              data-toggle="modal"
              data-target="#close-order-modal"
              :style="loading ? 'pointer-events: none' : ''"
              class="btn text-theme-36 font-medium flex items-center gap-1"
            >
              <LockIcon class="w-4 h-4" /> {{ i18n('common.close') }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: Modal -->
    <ConfirmtModal
      id="close-order-modal"
      :title="i18n('order.deliverOrder.title')"
      :message="i18n('order.deliverOrder.message')"
      :item="i18n('order.item')"
      @submit="doClose({ id: orderId, route: false })"
    >
      <template v-slot:icon>
        <LockIcon class="w-16 h-16 text-theme-36 mx-auto mt-3" />
      </template>
    </ConfirmtModal>
    <CancelOrderModal :id="orderId" :route="false" />
    <!-- END: Modal -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ConfirmtModal from '@/components/modals/confirm-modal.vue'
import CancelOrderModal from '@/views/order/modal/cancel-order-modal.vue'

export default {
  name: 'app-order-view-toolbar',
  components: {
    CancelOrderModal,
    ConfirmtModal
  },
  props: {
    record: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    orderId() {
      return this.record ? this.record.id : null
    },
    isShow() {
      return (
        this.record.status === 'inProgress' ||
        this.record.status === 'delivering'
      )
    }
  },
  methods: {
    ...mapActions({
      doCancel: 'order/form/doCancel',
      doClose: 'order/form/doClose'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
