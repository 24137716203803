<template>
  <div class="box p-5 rounded-md">
    <div
      class="flex items-center border-b border-slate-200 border-opacity-60 pb-5 mb-5"
    >
      <div class="font-medium text-base truncate">
        <app-i18n code="order.cart.title"></app-i18n>
      </div>
    </div>
    <div class="overflow-auto -mt-3">
      <table class="w-full text-left">
        <thead class="bg-slate-200/60">
          <tr>
            <th
              v-for="(col, index) in columns"
              :key="col.name"
              class="font-medium px-5 py-3 border-b-2 whitespace-nowrap"
              :class="index === 0 ? 'py-5' : 'text-right'"
            >
              {{ i18n(col.label) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="odd:bg-white even:bg-slate-100"
            v-for="(item, index) in items"
            :key="index"
          >
            <td class="px-5 border-b py-4">
              <div class="flex items-center gap-4">
                <div class="w-10 h-10 image-fit zoom-in">
                  <Tippy
                    tag="img"
                    data-action="zoom"
                    alt="Midone - HTML Admin Template"
                    class="rounded-lg border-2 border-white shadow-md tooltip"
                    :src="item.meal.images[0]"
                    :content="presenterLocalizationWithNull(item.meal, 'name')"
                  />
                </div>
                <span class="font-medium whitespace-nowrap">{{
                  presenterLocalizationWithNull(item.meal, 'name')
                }}</span>
              </div>
            </td>
            <td class="text-right px-5 py-3 border-b">
              <!-- {{
                presenterLocalization(
                  findSizeInMeal(item.meal, item.sizeId),
                  'name'
                )
              }} -->
              {{ presenterLocalizationWithNull(item.size, 'name') }}
            </td>
            <td class="text-right px-5 py-3 border-b">
              {{ presenterInteger(item, 'quantity') }}
            </td>
            <td class="text-right px-5 py-3 border-b">
              <!-- {{ formatPrice(findSizeInMeal(item.meal, item.sizeId).price) }} -->
              {{ formatPrice(item.size.price) }}
            </td>
            <td class="text-right px-5 py-3 border-b">
              {{ presenterLocalizationWithNull(item.meal.section, 'name') }}
            </td>
            <td class="text-right px-5 py-3 border-b">
              {{ presenterLocalizationWithNull(item.meal.category, 'name') }}
            </td>
            <td class="text-center px-5 py-3 border-b">
              <a
                v-if="item.addonsItems.length > 0"
                data-toggle="modal"
                data-target="#addons-view-modal-preview"
                href="javascript:;"
                class="text-theme-10 dark:text-theme-30 w-20"
                @click="doOpenModal(item.addonsItems)"
              >
                <EyeIcon class="w-4 h-4" :class="isRTL ? 'ml-2' : 'mr-2'" />
                <app-i18n code="common.view"></app-i18n>
              </a>
              <span v-else><app-i18n code="common.noAddons"></app-i18n></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <AddonsViewModal :addons="selectedAddons" @close="doCloseModal" />
</template>
<script>
import { mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'
import AddonsViewModal from '@/views/order/modal/addons-view-modal.vue'

export default {
  name: 'app-cart-details',
  components: {
    AddonsViewModal
  },
  props: {
    items: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL'
    })
  },
  data() {
    return {
      selectedAddons: null,
      columns: [
        {
          name: 'meal',
          field: 'meal',
          label: 'order.cart.fields.meal',
          align: 'center'
        },
        {
          name: 'size',
          field: 'size',
          label: 'order.cart.fields.size',
          align: 'center'
        },
        {
          name: 'quantity',
          field: 'quantity',
          label: 'order.cart.fields.quantity',
          align: 'center'
        },
        {
          name: 'price',
          field: 'price',
          label: 'order.cart.fields.price',
          align: 'center'
        },
        {
          name: 'section',
          field: 'section',
          label: 'order.cart.fields.section',
          align: 'center'
        },
        {
          name: 'category',
          field: 'category',
          label: 'order.cart.fields.category',
          align: 'center'
        },
        {
          name: 'addons',
          field: 'addons',
          label: 'order.cart.fields.addons',
          align: 'center'
        }
      ]
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    findSizeInMeal(meal, id) {
      if (id) {
        const { name, price } = meal.sizes.find((size) => size.id === id)
        return { name, price }
      }
      return {
        name: { en: 'default Size', ar: 'الحجم الافتراضى' },
        price: meal.price
      }
    },
    doOpenModal(addons) {
      this.selectedAddons = addons
      this.visibleModal = true
    },
    doCloseModal() {
      this.selectedAddons = null
      this.visibleModal = false
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE} ريال` : `${PRICE} SAR`
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterTime(row, fieldName) {
      return GenericModel.presenterTime(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterLocalizationWithNull(row, fieldName) {
      return GenericModel.presenterLocalizationWithNull(row, fieldName)
    }
  }
}
</script>
