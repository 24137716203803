<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="addons-view-modal-preview"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header justify-center">
          <h2 class="font-medium text-base">
            {{ isRTL ? 'الاضافات' : 'Addons' }}
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div class="modal-body">
          <div class="grid grid-cols-12 gap-4 gap-y-3">
            <div class="text-xl font-medium leading-none mt-5 col-span-4">
              {{ isRTL ? 'الاضافه' : 'Addon' }}
            </div>
            <div class="text-xl font-medium leading-none mt-5 col-span-4">
              {{ isRTL ? 'السعر' : 'Price' }}
            </div>

            <div class="text-xl font-medium leading-none mt-5 col-span-4">
              {{ isRTL ? 'الكمية' : 'Quantity' }}
            </div>
            <div
              class="col-span-12 grid grid-cols-12 border-t-2 border-opacity-60 border-slate-200"
              v-for="(addon, index) in addons"
              :key="index"
            >
              <div class="text-xl font-medium leading-none mt-5 col-span-4">
                {{ presenterLocalizationWithNull(addon.addon, 'name') }}
              </div>
              <div class="text-xl font-medium leading-none mt-5 col-span-4">
                {{ formatPrice(addon.addon.price) }}
              </div>

              <div class="text-xl font-medium leading-none mt-5 col-span-4">
                {{ addon.quantity }}
              </div>
            </div>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer flex justify-center">
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-outline-secondary w-20"
            @click="$emit('close')"
          >
            <app-i18n code="common.close"></app-i18n>
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  props: {
    addons: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    })
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE} ريال` : `${PRICE} SAR`
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterLocalizationWithNull(row, fieldName) {
      return GenericModel.presenterLocalizationWithNull(row, fieldName)
    }
  }
}
</script>

<style></style>
