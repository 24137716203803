<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">
        <app-i18n code="order.view.title"></app-i18n>
      </h2>
    </div>
    <div style="width: 100%; height: 80vh" class="intro-y box overflow-hidden mt-5 flex justify-center" v-if="loading">
      <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
    </div>
    <!-- BEGIN: Order -->
    <app-order-view-toolbar v-if="record && !loading" :record="record" :loading="loading" />
    <div class="intro-y grid grid-cols-11 gap-5 mt-5" v-if="record && !loading">
      <div class="col-span-12 lg:col-span-4 2xl:col-span-3">
        <app-order-view-details :record="record" :loading="loading" />
        <app-order-view-user type="client" :user="record.client" :address="record.address" v-if="record.client" />
        <app-order-view-user @cancelCaptain="handleCaptainCancelling" :record="record" type="captain"
          :user="record.captain" v-if="record.captain" />
        <app-order-assign-to-captian type="captain" :orderId="id"
          v-else-if="!record.captain && record.status !== 'shadow'" />
        <app-order-view-user type="chef" :user="record.chef" :address="record.chef.address" v-if="record.chef" />
      </div>
      <div class="col-span-12 lg:col-span-7 2xl:col-span-8 flex flex-col gap-5">
        <app-cart-details :items="record.cart.items" />
        <div class="intro-y box p-5 rounded-md" v-if="record.status != 'shadow'">
          <div style="width: 100%; height: 300px" class="intro-y overflow-hidden mt-5 flex justify-center"
            v-if="locationLoading">
            <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
          </div>
          <div v-else>
            <Map v-if="record.captain && captainLocation" class="report-maps bg-slate-200 rounded-md"
              :options="mapOptions" :startPos="record.chef.address" :destination="record.address"
              style="width: 100%; height: 300px" :captainCoords="captainCoords" />
            <div v-else class="flex flex-col items-center justify-center h-full">
              <lottie-player src="/captain-location.json" speed="1" autoplay loop background="transparent"
                style="width: 300px; height: 300px"></lottie-player>
              <h2 class="font-medium text-lg">
                {{ i18n('order.view.noCaptain') }}
              </h2>
              <!-- <h2
              class="font-medium text-lg"
              v-if="record.captain && !record.captain.currentLat"
            >
              {{ i18n('order.view.noCaptainLocation') }}
            </h2> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Order -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import OrderViewToolbar from '@/views/order/order-view-toolbar.vue'
import OrderViewDetails from '@/views/order/order-view-details.vue'
import OrderViewUser from '@/views/order/order-view-user.vue'
import OrderAssingToCaptian from '@/views/order/order-assign-to-captain.vue'
import OrderCartDetails from '@/views/order/order-cart-details.vue'
import Map from '@/components/map/tracking-map.vue'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    Map,
    [OrderViewToolbar.name]: OrderViewToolbar,
    [OrderViewDetails.name]: OrderViewDetails,
    [OrderViewUser.name]: OrderViewUser,
    [OrderAssingToCaptian.name]: OrderAssingToCaptian,
    [OrderCartDetails.name]: OrderCartDetails
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'order/form/record',
      captainLocation: 'order/form/captainLocation',
      findLoading: 'order/form/findLoading',
      locationLoading: 'order/form/locationLoading',
      saveLoading: 'order/form/saveLoading'
    }),
    loading() {
      return this.findLoading || this.saveLoading
    },
    captainCoords() {
      return {
        name: `${this.record.captain.fullName || ''}`,
        latitude: this.captainLocation?.lat || 0,
        longitude: this.captainLocation?.lng || 0,
        marker: '/CAR.svg'
      }
    },
    mapOptions() {
      return {
        location: {
          lat: this.captainLocation?.lat || 0,
          lng: this.captainLocation?.lng || 0
        },
        zoom: 15
      }
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.orders')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.orders')
    }
  },
  async mounted() {
    await this.doFind(this.id)
  },
  methods: {
    ...mapActions({
      doFind: 'order/form/doFind',
      doCancelAssignCaptain: 'order/form/doCancelAssignCaptain'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    async handleCaptainCancelling() {
      await this.doCancelAssignCaptain({ orderId: this.record?.id, captainId: this.record?.captain?.id })
    },
    doBack() {
      this.$router.push('/orders')
    }
  }
}
</script>
