<template>
  <div class="box p-5 rounded-md">
    <div
      class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5"
    >
      <div class="font-medium text-base truncate">
        <app-i18n code="order.view.orderDetails"></app-i18n>
      </div>
    </div>
    <div class="flex items-center gap-2">
      <ClipboardIcon class="w-4 h-4 text-slate-500" />
      <app-i18n code="order.fields.orderNumber"></app-i18n>:
      <span class="font-medium text-theme-10">
        {{ record.orderNo ? `#${record.orderNo}` : 'ـــــــــ' }}
      </span>
    </div>
    <div class="flex items-center mt-3 gap-2">
      <DollarSignIcon class="w-4 h-4 text-slate-500" />
      {{ i18n('order.fields.cartAmount') }}:
      <span class="font-medium text-theme-21">{{
        formatPrice(record.cartAmount)
      }}</span>
    </div>
    <div class="flex items-center mt-3 gap-2">
      <DollarSignIcon class="w-4 h-4 text-slate-500" />
      {{ i18n('order.fields.deliveryFees') }}:
      <span class="font-medium text-theme-21">{{
        formatPrice(record.deliveryFees)
      }}</span>
    </div>
    <div class="flex items-center mt-3 gap-2">
      <DollarSignIcon class="w-4 h-4 text-slate-500" />
      {{ i18n('order.fields.totalAmount') }}:
      <span class="font-medium text-theme-21">{{
        formatPrice(record.totalAmount)
      }}</span>
    </div>
    <div class="flex items-center mt-3 gap-2">
      <CalendarIcon class="w-4 h-4 text-slate-500" />
      {{ i18n('order.fields.createdAt') }}:
      <span class="font-medium">{{ presenterDate(record, 'createdAt') }}</span>
    </div>
    <div class="flex items-center mt-3 gap-2">
      <ActivityIcon class="w-4 h-4 text-slate-500" />
      {{ i18n('order.fields.status') }}:
      <span
        class="font-medium"
        :class="{
          'text-gray-600': record.status == 'inProgress',
          'text-theme-24': record.status == 'cancelled',
          'text-theme-10': record.status == 'closed'
        }"
        >{{ i18n(`order.enumerators.status.${record.status}`) }}</span
      >
    </div>
    <div class="flex items-center mt-3 gap-2">
      <CreditCardIcon class="w-4 h-4 text-slate-500" />
      {{ i18n('order.fields.paymentMethod') }}:
      <span
        class="font-medium"
        :class="{
          'text-theme-10': record.paymentMethod == 'visa',
          'text-theme-36': record.paymentMethod == 'wallet'
        }"
        >{{
          i18n(`order.enumerators.paymentMethod.${record.paymentMethod}`)
        }}</span
      >
    </div>
    <div class="flex items-center mt-3 gap-2">
      <ClockIcon class="w-4 h-4 text-slate-500" />
      {{ i18n('order.fields.deliveryTime') }}:
      <span class="font-medium"
          >{{ presenterDateTime(record, 'deliveryTime') }}</span
        >
    </div>
    <div class="flex items-center mt-3 gap-2">
      <ClockIcon class="w-4 h-4 text-slate-500" />
      {{ i18n('order.fields.pickUpTime') }}:
      <span class="font-medium">{{
          presenterDateTime(record, 'pickUpTime')
        }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  name: 'app-order-view-details',
  props: {
    record: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    })
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE} ريال` : `${PRICE} SAR`
    },
    presenterDateTime(row, fieldName) {
      return GenericModel.presenterDateTime(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    }
  }
}
</script>

<style></style>
