<template>
  <div class="box p-5 rounded-md mt-5">
    <div
      class="flex items-center justify-between border-b border-slate-200/60 pb-2 mb-5"
    >
      <div class="font-medium text-base truncate">
        <app-i18n :code="`order.view.${type}Details`"></app-i18n>
      </div>
      <div class="flex gap-4">
        <button
          @click="shareLocation(address)"
          class="text-theme-10 underline py-2 rounded font-semibold flex gap-1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
            />
          </svg>
          {{ $t('common.shareLocation') }}
        </button>
        <button
          @click="$emit('cancelCaptain')"
          class="text-theme-35 underline py-2 rounded font-semibold"
          v-if="showCancelButton"
        >
          {{ $t('common.cancel') }}
        </button>
      </div>
    </div>
    <div class="flex items-center gap-2">
      <UserIcon class="w-4 h-4 text-slate-500" />
      {{ i18n(`order.fields.name`) }}:
      <router-link
        :to="`/${type}s/${userId}`"
        target="_blank"
        class="flex items-center"
      >
        <span class="font-medium text-theme-10">{{
          presenter(user, 'fullName')
        }}</span>
      </router-link>
    </div>
    <div class="flex items-center mt-3 gap-2">
      <PhoneIcon
        :style="isRTL ? 'transform: rotateY(180deg)' : ''"
        class="w-4 h-4 text-slate-500"
      />
      {{ i18n(`order.fields.phoneNumber`) }}:
      <div style="direction: ltr !important">
        {{ presenter(user, 'phoneNumber') }}
      </div>
    </div>
    <div class="flex items-center gap-2 mt-3" v-if="user && user.email">
      <MailIcon class="w-4 h-4 text-slate-500" />
      {{ presenter(user, 'email') }}
    </div>
    <div class="flex items-center mt-3 gap-2" v-if="address">
      <MapPinIcon class="w-4 h-4 text-slate-500" />
      {{ i18n(`order.fields.address`) }}:
      <a
        :href="
          `https://www.google.com/maps/search/?api=1&query=${address.lat},${address.long}`
        "
        target="_blank"
        class="text-theme-10"
        >{{
          `${address.building}. ${address.street} ${isRTL ? 'شارع' : 'Street'}.`
        }}</a
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  name: 'app-order-view-user',
  props: {
    record: {
      type: Object
    },
    type: {
      type: String
    },
    user: {
      type: Object,
      required: true
    },
    address: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL'
    }),
    userId() {
      return this.user.id
    },
    showCancelButton() {
      return (
        this.type == 'captain' &&
        this?.record?.status != 'closed' &&
        this?.record?.status != 'cancelled'
      )
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    shareLocation(address) {
      const { lat, long } = address || {
        lat: this.user.currentLat,
        long: this.user.currentLng
      }
      const message = `Check out Client location: https://maps.google.com/?q=${lat},${long}`
      window.open(
        `https://wa.me/?text=${encodeURIComponent(message)}`,
        '_blank'
      )
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    }
  }
}
</script>
