<template>
  <GoogleMapLoader :init="init" />
</template>
<script>
/* eslint-disable */
import { watch } from 'vue'
export default {
  props: {
    options: {
      type: Object
    },
    startPos: {
      type: Object,
      required: true
    },
    destination: {
      type: Object,
      required: true
    },
    captainCoords: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const init = async initializeMap => {
      const { google, map } = await initializeMap({
        apiKey: 'AIzaSyBfuKMUnoTBYHwQCcyZV-5k-3vw0r200vo',
        config(google) {
          return {
            mapId: 'd338cf1d37092405',
            zoom: props.options.zoom || 15,
            styles: [],
            zoomControl: true,
            zoomControlOptions: {
              position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            streetViewControl: props.options.streetViewControl || false,
            center: {
              lat: props.startPos.lat,
              lng: props.startPos.long
            }
          }
        }
      })
      // const marker = new google.maps.Marker({
      //   map: map,
      //   position: new google.maps.LatLng(
      //     parseFloat(props.startPos.lat),
      //     parseFloat(props.startPos.long)
      //   ),
      //   icon: {
      //     url:
      //       props.captainCoords.marker ||
      //       require('@/assets/images/map-marker.png'),
      //     scaledSize: new google.maps.Size(40, 40)
      //   }
      // })
      const content = document.createElement('div')
      content.id = 'markerIcon'
      content.innerHTML = `
      <img  src="${props.captainCoords.marker ||
        require('@/assets/images/map-marker.png')}">
      `
      const { AdvancedMarkerElement } = await google.maps.importLibrary(
        'marker'
      )
      const marker = new AdvancedMarkerElement({
        map: map,
        position: new google.maps.LatLng(
          parseFloat(props.captainCoords.latitude),
          parseFloat(props.captainCoords.longitude)
        ),
        content
      })
      const directionsService = new google.maps.DirectionsService()
      const directionsRenderer = new google.maps.DirectionsRenderer({
        preserveViewport: true
      })
      directionsRenderer.setMap(map)

      const bounds = new google.maps.LatLngBounds()
      bounds.extend(
        new google.maps.LatLng(
          props.captainCoords.latitude,
          props.captainCoords.longitude
        )
      )
      bounds.extend(
        new google.maps.LatLng(props.startPos.lat, props.startPos.long)
      )
      // Fit the map to the bounds
      map.fitBounds(bounds)

      // Function to update the marker position
      const updateMarker = () => {
        const newLatLng = new google.maps.LatLng(
          props.captainCoords.latitude,
          props.captainCoords.longitude
        )
        marker.setPosition(newLatLng)
        map.panTo(newLatLng)
      }

      // Function to animate the marker position
      const animateMarker = (startLatLng, endLatLng, duration) => {
        const startTime = new Date().getTime()
        const endTime = startTime + duration
        // const step = 16 // Update every 16ms (roughly 60fps)
        const animate = () => {
          const now = new Date().getTime()
          const progress = Math.min(1, (now - startTime) / duration)

          const newLatLng = new google.maps.LatLng(
            startLatLng.lat() +
              progress * (endLatLng.lat() - startLatLng.lat()),
            startLatLng.lng() + progress * (endLatLng.lng() - startLatLng.lng())
          )

          marker.setPosition(newLatLng)
          map.panTo(newLatLng)

          if (now < endTime) {
            // Continue animating until endTime is reached
            requestAnimationFrame(animate)
          }
        }

        animate()
      }
      // Function to update the directions and draw the path
      const updateDirections = () => {
        const startLatLng = new google.maps.LatLng(
          props.startPos.lat,
          props.startPos.long
        )
        const endLatLng = new window.google.maps.LatLng(
          props.destination.lat,
          props.destination.long
        )
        const request = {
          origin: startLatLng,
          destination: endLatLng,
          travelMode: 'DRIVING'
        }
        directionsService.route(request, (result, status) => {
          if (status === 'OK') {
            directionsRenderer.setDirections(result)
          }
        })
      }
      updateDirections()

      // Function to update the marker direction accoriding to two points
      const rotateMarker = (startPos, endPos) => {
        const heading = google.maps.geometry.spherical.computeHeading(
          startPos,
          endPos
        )
        marker.content.style.transform = `rotate(${heading}deg)`
      }
      // Watch for changes in the marker location prop
      // watch(
      //   () => props.captainCoords,
      //   () => {
      //     updateMarker() // Update the marker position when marker location prop changes
      //   }
      // )

      // Watch for changes in the marker location prop
      watch(
        () => props.captainCoords,
        (newMarkerLocation, oldMarkerLocation) => {
          // Get the current position of the marker
          const currentLatLng = new google.maps.LatLng(
            oldMarkerLocation.latitude,
            oldMarkerLocation.longitude
          )
          const newLatLng = new google.maps.LatLng(
            newMarkerLocation.latitude,
            newMarkerLocation.longitude
          )
          // Animate the marker position over a duration of 1 second (1000ms)
          rotateMarker(currentLatLng, newLatLng)
          animateMarker(currentLatLng, newLatLng, 1000)
        }
      )

      // Watch for changes in the start and end location props
      watch(
        () => [props.startPos, props.destination],
        () => {
          rotateMarker(props.startPos, props.destination)
          updateDirections() // Update the directions and draw the path when start or end location props change
        }
      )

      const infoWindow = new google.maps.InfoWindow({
        minWidth: 'fit-content',
        maxWidth: 400
      })
      const infowincontent = `
              <div class="font-medium">
                ${props.captainCoords.name}
              </div>
              <div class="mt-1 text-gray-600">
                Latitude: ${props.captainCoords.latitude}, Longitude: ${props.captainCoords.longitude}
              </div>`

      google.maps.event.addListener(marker, 'click', function() {
        infoWindow.setContent(infowincontent)
        google.maps.event.addListener(infoWindow, 'domready', function() {
          cash('.arrow_box')
            .closest('.gm-style-iw-d')
            .removeAttr('style')
          cash('.arrow_box')
            .closest('.gm-style-iw-d')
            .attr('style', 'overflow:visible')
          cash('.arrow_box')
            .closest('.gm-style-iw-d')
            .parent()
            .removeAttr('style')
        })

        infoWindow.open(map, marker)
      })

      // google.maps.event.addListener(map, 'click', (event) => {
      //   const currentLatLng = new google.maps.LatLng(
      //     marker.position.lat(),
      //     marker.position.lng()
      //   )
      //   const newLatLng = new google.maps.LatLng(
      //     event.latLng.lat(),
      //     event.latLng.lng()
      //   )
      //   animateMarker(currentLatLng, newLatLng, 1000)
      // })
    }

    return {
      init
    }
  }
}
</script>
